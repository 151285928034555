import { FC } from 'react';
import { CustomCard } from '../../AccountUsersCard/AccountUsersCard';
import { ToggleContainer } from '../styles';
import { Title } from '../../AccountsHeader/styles';
import Switch from 'components/switch';
import { ToggleSwitchWrapper, ToggleWrapper } from '../styles';
import Tooltip from 'components/tooltip';

interface IAccountXapiStaffCard {
  xapi_feature: boolean;
}

const AccountXapiStaffCard: FC<IAccountXapiStaffCard> = ({ xapi_feature }) => {
  const tooltipText = xapi_feature
    ? 'Degreed integration is enabled for this account'
    : 'Degreed integration is disabled for this account';
  return (
    <CustomCard dashboard={true}>
      <ToggleContainer>
        <ToggleWrapper>
          <Title>Degreed Integration</Title>
          <ToggleSwitchWrapper>
            <Tooltip placement="right" destroyTooltipOnHide title={tooltipText}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Switch disabled checked={xapi_feature} style={{ marginRight: '10px' }} />
              </span>
            </Tooltip>
          </ToggleSwitchWrapper>
        </ToggleWrapper>
      </ToggleContainer>
    </CustomCard>
  );
};

export default AccountXapiStaffCard;
